.main-banner {
  z-index: 1;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 45px;
      z-index: 1;
      width: auto;

      @include maxWidth($media_lg) {
        bottom: 20px;
      }
      li {
        width: fit-content;
        height: fit-content;
        margin: 0;
        &:not(:last-child) {
          margin: 0 5px 0 0;
        }
        &.slick-active {
          button {
            width: 68px;
            background: $primaryColor;
          }
        }

        button {
          width: 23px;
          height: 8px;
          border-radius: 21px;
          background: rgba(90, 96, 101, 0.5);
          padding: 4px;

          &:before {
            display: none;
          }
        }
      }
    }
  }

}

.banner-slide {
  height: 400px;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 60px;

  @include media("<568px") {
    height: 75vw;
    padding-left: 10vw;
    padding-top: 10vw;
    align-items: flex-start;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    &::after {
      content: "";
      background: linear-gradient(22.01deg, #101416 14.38%, rgba(16, 20, 22, 0) 85.61%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 5;
  }
  &__title {
    font-weight: 900;
    font-size: 44px;
    line-height: 1.3;
    margin-bottom: 10px;

    @include media("<568px") {
      font-size: 26px;
    }
  }
  &__sub-title {
    font-size: 18px;
    line-height: 1.3;
    color: rgba(250,250,250, .9);

    @include media("<568px") {
      font-size: 14px;
    }
  }
  &__btn {
    margin-top: 15px;
  }
}