.about-us-section {
  position: relative;
  padding-bottom: 70px;
  margin-top: 125px;
  @include media('<=860px') {
    margin-top: 65px;
    padding-bottom: 50px;
  }
  .btn {
    min-width: 210px;
    @include media("<568px") {
      width: 100%;
    }
  }
  &__title {

    &.ant-typography {
      font-size: 44px;
      @include media('<=860px') {
        font-size: 36px;
      }
      @include media("<568px") {
        font-size: 28px;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }
  &__img {
    width: 577px;
    position: absolute;
    right: 100px;
    top: 0;

    @include media('<old') {
      width: 450px;
      right: -50px;
    }
    @include media('<=860px') {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 450px;
    width: 100%;
    background: linear-gradient(180.9deg, #43484D 17.18%, #33363C 99.27%);

  }
}
.about-list {
  list-style: none;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  margin-top: 35px;
  margin-bottom: 50px;

  @include media("<568px") {
    font-size: 16px;
  }
  li {
    +li {
      margin-top: 5px;
    }
    position: relative;
    padding-left: 25px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 10px;
      height: 10px;
      background-color: $primaryColor;
      border-radius: 50%;
    }
  }
}